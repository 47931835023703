













import { mixins } from 'vue-class-component';
import { Component, Ref } from 'vue-property-decorator';
import QueryParserMixin, { QueryType } from '~/mixins/query-parser-mixin';
import appendHash from '~/utils/AppendAccessTokenHash';
import IframeHolderMixin from '~/mixins/iframe-holder';

@Component({
    components: {},
    computed: {},
    metaInfo() {
        return {
            title: this.courseId ? `修改課程 - ${this.courseId}` : '創建課程',
        };
    },
})
export default class Course extends mixins(QueryParserMixin, IframeHolderMixin) {
    private messageHandler: any = null;
    courseId: string = '';
    canEdit: boolean = false;
    editingVersion: number = 1;
    showingSectionsString: string = '';

    //  UI DATA
    queryDef = [
        { localVar: 'editingVersion', queryStr: 'version', type: QueryType.Number },
        { localVar: 'courseId', queryStr: 'courseId', type: QueryType.String },
        { localVar: 'showingSectionsString', queryStr: 'sections', type: QueryType.String },
    ];

    // iframe Course
    @Ref() iframeCourseWindow: HTMLIFrameElement;

    iframeCourseLink: string = '';

    async handleMessage(event: MessageEvent) {
        const messageType = event.data.name || event.data.type;
        switch (messageType) {
            case 'URLSlug':
                const path = event.data.path;
                const fullPath = this.$router.resolve(path).href;
                window.open(fullPath, '_blank');
                break;
            case 'navigate':
                const pathNav = event.data.path;
                this.courseId = event.data.courseId;
                this.$router.push(pathNav);
                this.updateIframeCourseLink();
                break;
        }
    }

    async mounted() {
        // Add event listeners
        this.messageHandler = this.handleMessage.bind(this);
        window.addEventListener('message', this.messageHandler, false);

        // Extract courseId from the current window's URL
        const parentUrl = window.location.href;
        const courseIdMatch = parentUrl.match(/\/edit-course\/([^?]+)/);

        // Update the class-level courseId property
        this.courseId = courseIdMatch ? courseIdMatch[1] : '';

        this.updateIframeCourseLink()
    }
    async created() {
        this.parseQuery();
    }

    async beforeDestroy() {
        window.removeEventListener('message', this.messageHandler);
    }

    async updateIframeCourseLink() {
        let baseUrl = '';
        const params = {
            noframe: '1',
            aa: '1',
            bg: 'transparent',
        };

        // Check if courseId is available and construct the base URL accordingly
        if (this.courseId) {
            baseUrl = `${process.env.VUE_APP_DOMAIN_PORTAL_TUTOR}/course/edit-course/${this.courseId}`;
        } else {
            baseUrl = `${process.env.VUE_APP_DOMAIN_PORTAL_TUTOR}/course/new-course/`;
        }

        // Create the full URL with search parameters
        const url = new URL(baseUrl);

        for (const [key, value] of Object.entries(params)) {
            if (value !== null && value !== undefined) url.searchParams.set(key, value.toString());
        }

        // Append hash with authentication tokens and other necessary data
        const hashData = {
            access_token: this.$auth.accessToken,
            refresh_token: this.$auth.refreshToken,
            refresh_token_exp: this.$auth.refreshTokenExpire.toString(),
            mid: this.$auth.memberId,
        };

        // Update iframeCourseLink with the new URL
        this.iframeCourseLink = appendHash(url.href, hashData);
    }
}
